<template>
  <div
    class="content-cancelamento vx-row px-8 relative"
    v-if="apolice.PolicyUniqueId"
  >
    <section class="vx-col w-full md:w-8/12 pr-10">
      <div class="vx-row">
        <div class="vx-col pb-5 w-full">
          <p class="font-bold pb-3 pr-3 w-full">
            Nº Apólice

            <span class="font-bold text-sm text-grey-700 word-wrap">
              {{ apolice.PolicyNumber || "-" }}
            </span>
          </p>
        </div>
      </div>

      <div class="vx-row pt-8">
        <div class="vx-col w-full sm:w-1/2 md:w-5/12">
          <p class="font-bold pb-3 w-full">Tomador</p>
          <p class="font-bold w-full text-sm text-grey-700">
            {{ (apolice.PolicyHolder || {}).Name || "-" }}
          </p>
          <p class="w-full text-xs">
            {{
              $utils.maskDocument((apolice.PolicyHolder || {}).Document) || "-"
            }}
          </p>

          <div vs-align="flex-end" class="field-edit pt-8 pb-5">
            <p class="font-bold w-full">Endereço do Tomador</p>
            <p class="w-full text-sm text-grey-700">
              {{ policyHolderAddress || "-" }}
            </p>
          </div>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-5/12">
          <p class="font-bold pb-3 w-full">Segurado</p>
          <p class="font-bold w-full text-sm text-grey-700">
            {{ (apolice.Insured || {}).Name || "-" }}
          </p>
          <p class="w-full text-xs">
            {{ $utils.maskDocument((apolice.Insured || {}).Document) || "-" }}
          </p>

          <div class="mb-0 field-edit pt-8 pb-5">
            <p class="font-bold w-full">Endereço do Segurado</p>
            <p class="w-full text-sm text-grey-700">
              {{ insuredAddress || "-" }}
            </p>
          </div>
        </div>
      </div>

      <div class="vx-row mt-8">
        <div class="vx-col w-full sm:w-1/2 md:w-3/12">
          <p class="font-bold pt-8 pb-3 w-full">Modalidade Principal</p>
          <p class="w-full text-sm text-grey-700">
            {{ (apolice.Modality || {}).Name }}
          </p>
        </div>
        <div class="vx-col w-full sm:w-1/2 md:w-3/12">
          <div class="pt-8 pb-5 field-edit">
            <p class="font-bold w-full">IS Principal</p>
            <p class="font-bold w-full text-sm text-grey-700">
              {{
                (apolice.InsuredAmountValue || 0).toLocaleString(
                  "pt-BR",
                  this.$utils.moneyFormatOptions
                )
              }}
            </p>
          </div>
        </div>
        <div class="vx-col w-full sm:w-1/2 md:w-3/12">
          <div class="pt-8 pb-5 field-edit">
            <p class="font-bold w-full">Vigência Principal</p>
            <p
              class="w-full text-sm text-grey-700"
              v-if="endosso.StartDate"
              style="border-bottom: 1px solid #ff8809; margin-bottom: 7px;"
            >
              {{
                `${$utils.dateToDDMMYYYY(
                  endosso.StartDate
                )} à ${$utils.dateToDDMMYYYY(apolice.EndDate)}`
              }}
            </p>
            <p class="w-full text-sm text-grey-700" v-else>
              {{
                `${$utils.dateToDDMMYYYY(
                  apolice.StartDate
                )} à ${$utils.dateToDDMMYYYY(apolice.EndDate)}`
              }}
            </p>
            <!-- <p v-if="enableStartDateChange">
              <a class="edit" href="#" @click.prevent="modalStartDate = true"
                >Alterar início de vigência
              </a>
            </p> -->
          </div>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-3/12">
          <div class="pt-8 pb-5 field-edit">
            <p class="font-bold w-full">Taxa aplicada (%)</p>
            <p class="w-full text-sm text-grey-700">
              {{ (apolice || {}).RiskRate }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="vx-row mt-8"
        v-if="
          apolice.ComplementaryModality && apolice.ComplementaryModality.Id > 0
        "
      >
        <div class="vx-col w-full sm:w-1/2 md:w-3/12">
          <p class="font-bold pt-8 pb-3 w-full">Modalidade Complementar</p>
          <p class="w-full text-sm text-grey-700">
            {{ apolice.ComplementaryModality.Name }}
          </p>
        </div>
        <div class="vx-col w-full sm:w-1/2 md:w-3/12">
          <div class="pt-8 pb-5 field-edit">
            <p class="font-bold w-full">IS Complementar</p>
            <p class="font-bold w-full text-sm text-grey-700">
              {{
                (apolice.ComplementaryInsuredAmountValue || 0).toLocaleString(
                  "pt-BR",
                  this.$utils.moneyFormatOptions
                )
              }}
            </p>
          </div>
        </div>
        <div class="vx-col w-full sm:w-1/2 md:w-3/12">
          <div class="pt-8 pb-5 field-edit">
            <p class="font-bold w-full">Vigência Complementar</p>
            <p class="w-full text-sm text-grey-700">
              {{
                `${$utils.dateToDDMMYYYY(
                  apolice.ComplementaryModalityStartDate
                )} à ${$utils.dateToDDMMYYYY(
                  apolice.ComplementaryModalityEndDate
                )}`
              }}
            </p>
          </div>
        </div>

        <div class="vx-col w-full sm:w-1/2 md:w-3/12">
          <div class="pt-8 pb-5 field-edit">
            <p class="font-bold w-full">Taxa aplicada (%)</p>
            <p class="w-full text-sm text-grey-700">
              {{ (apolice || {}).RiskRate }}
            </p>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col pt-5">
          <p class="font-bold pb-3 w-full">Objeto</p>
          <p class="w-full text-sm object-justify" v-html="objeto || '-'"></p>
        </div>
      </div>
    </section>

    <aside class="vx-col w-full md:w-4/12 md:pl-24">
      <div class="vx-row">
        <div
          class="vx-col w-full pb-5"
          v-if="apolice.Coverages && apolice.Coverages.length"
        >
          <p class="font-bold pt-8 pb-3 w-full">Coberturas adicionais</p>
          <div
            class="w-full flex"
            v-for="(coberturas, index) in apolice.Coverages"
            :key="`icon-${index}`"
          >
            <p class="text-sm text-grey-700 w-full">
              <i class="text-lg onpoint-check-circle text-primary pr-3"></i>
              {{ coberturas.Name }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="vx-row"
        :class="{
          'mt-8': apolice.Coverages && apolice.Coverages.length,
          'border-none': !apolice.Coverages || !apolice.Coverages.length
        }"
      >
        <div
          class="vx-col w-full"
          :class="{
            'pt-8': apolice.Coverages && apolice.Coverages.length
          }"
        >
          <p class="font-bold pt-3 pb-3 w-full">Tipo do endosso</p>
          <p class="w-full text-sm object-justify">
            {{ tipoEndossoDescription }}
          </p>
        </div>
      </div>

      <vs-row class="vx-row mt-5">
        <vs-col class="container-element pt-3" vs-w="12">
          <p class="label-content label">Prêmio da apólice</p>
          <currency-input
            :value="(apolice || {}).InsurancePremium"
            class="w-full vs-inputx vs-input--input large hasValue"
            disabled
          />
        </vs-col>
        <vs-col
          class="container-element pt-3 mt-4"
          vs-w="12"
          v-if="enableStartDateChange"
        >
          <p class="label-content label">Data do Cancelamento</p>
          <datepicker
            :language="ptBR"
            class="w-full endosso-date"
            format="dd/MM/yyyy"
            v-model="newStartDate"
            :disabled-dates="disabledDates"
          />
        </vs-col>
      </vs-row>

      <revisao-premio
        :loadingPremium="loadingPremium"
        :restituir="restituir"
        :IsBankInformationNeeded="IsBankInformationNeeded"
        @updatePolicyHolderBankInfo="updateEndosso"
      />
    </aside>
    <vs-popup
      v-loading="dateLoading"
      title="Alterar Início de Vigência"
      class="date-new"
      :active.sync="modalStartDate"
    >
      <vs-col
        vs-lg="12"
        vs-md="12"
        :class="`container-element`"
        style="margin-bottom: 20px;"
      >
        <el-alert
          title="Atenção!"
          type="warning"
          description="A alteração da data de início de vigência deve estar restrita a datas retroativas. Não será permitida a definição de uma data futura!"
          show-icon
        >
        </el-alert>
      </vs-col>
      <vs-col vs-lg="4" vs-md="4" :class="`container-element`">
        <p class="label-content label">Novo Início de Vigência</p>
        <datepicker
          style="height: 50px;"
          :language="ptBR"
          class="w-full"
          format="dd/MM/yyyy"
          v-model="newStartDate"
          :disabled-dates="disabledDates"
        />
      </vs-col>
      <vs-col
        vs-lg="4"
        vs-md="4"
        :class="`container-element`"
        style="padding: 0px 5px"
      >
        <p class="label-content label">Prazo em dias</p>
        <vs-input
          v-if="deadlineDays"
          class="w-full"
          size="large"
          :value="deadlineDays"
          :disabled="true"
        />
      </vs-col>
      <vs-col
        vs-lg="4"
        vs-md="4"
        :class="`container-element`"
        style="padding: 0px 5px"
      >
        <p class="label-content label">Fim de Vigência</p>
        <vs-input
          v-if="endDate"
          class="w-full"
          size="large"
          :value="$utils.dateToDDMMYYYY(endDate)"
          :disabled="true"
        />
      </vs-col>
      <vs-col
        align="right"
        vs-lg="12"
        vs-md="12"
        :class="`container-element`"
        style="padding: 15px 5px"
      >
        <vs-button
          @click="modalStartDate = false"
          color="red"
          type="border"
          class="max-w-lg"
          :disabled="!newStartDate"
          style="margin-right: 12px;"
        >
          Voltar
        </vs-button>
        <vs-button
          @click="saveDate()"
          color="primary"
          type="border"
          class="max-w-lg"
          :disabled="!newStartDate"
        >
          Prosseguir
        </vs-button>
      </vs-col>
    </vs-popup>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { mapGetters, mapActions, mapMutations } from "vuex";
import RevisaoPremio from "./components/RevisaoPremio.vue";
import TagHelpers from "@/helpers/TagHelpers";
import { ptBR } from "vuejs-datepicker/dist/locale";
import instance from "@/axios";
import environment from "@/environment";

export default {
  name: "dados-apolice",
  components: { RevisaoPremio, Datepicker },
  data() {
    return {
      Essor: false,
      disabledDates: {
        to: new Date(2020, 0, 1),
        from: new Date(2100, 0, 26)
      },
      ptBR,
      enableStartDateChange: false,
      endDate: false,
      dateLoading: false,
      deadlineDays: false,
      callbackDate: false,
      newStartDate: new Date(this.$moment().format()),
      modalStartDate: false,
      loadingPremium: false,
      restituir: false,
      IsBankInformationNeeded: false,
      tipoEndossoDescription: "Não definido."
    };
  },
  computed: {
    ...mapGetters("apolice-module", ["apolice", "Rules"]),
    ...mapGetters("endosso-module", ["endosso", "seguradoraTags"]),
    insuredAddress() {
      return `${this.apolice.Insured.Location.AddressName}, ${this.apolice.Insured.Location.Number} - ${this.apolice.Insured.Location.Neighborhood}, ${this.apolice.Insured.Location.ZipCode}`;
    },

    policyHolderAddress() {
      return `${this.apolice.PolicyHolder.Location.AddressName}, ${this.apolice.PolicyHolder.Location.Number} - ${this.apolice.PolicyHolder.Location.Neighborhood}, ${this.apolice.PolicyHolder.Location.ZipCode}`;
    },

    objeto() {
      let text = "";
      let defaultTags = [];

      ((this.apolice.ContractualTerms || {}).Object || []).forEach(element => {
        text += element.Value;

        let values = JSON.parse(element.JSONTagValue || "{}");
        let jsonTagValues = [];
        for (var value of Object.keys(values)) {
          jsonTagValues.push({ Name: value, Value: values[value] });
        }

        defaultTags = [...defaultTags, ...jsonTagValues];
      });
      defaultTags = [
        ...defaultTags,
        ...TagHelpers.defaultTags(this.apolice, this.seguradoraTags)
      ];

      return TagHelpers.replaceDefaultTags(text, defaultTags, []);
    }
  },
  watch: {
    newStartDate(newVal, old) {
      this.saveDate()
    },
    Rules(newVal, old) {
      if (this.Essor) {
        // console.log("tem regras aqui:", newVal);
        if (newVal[1].StartDate) {
          this.enableStartDateChange = true;
          let RulesStart = newVal[1].StartDate;
          const EYYYY2 = this.$moment(RulesStart).format("YYYY");
          const EMM2 = this.$moment(RulesStart).format("MM");
          const EDD2 = this.$moment(RulesStart).format("DD");
          this.disabledDates.to = new Date(EYYYY2, EMM2 - 1, EDD2);
        }
        if (newVal[2].StartDate) {
          let RulesEnd = newVal[2].StartDate;
          const EYYYY = this.$moment(RulesEnd).format("YYYY");
          const EMM = this.$moment(RulesEnd).format("MM");
          const EDD = this.$moment(RulesEnd).format("DD");
          let finalDay = parseInt(EDD);
          this.disabledDates.from = new Date(EYYYY, EMM - 1, EDD);
        }
      }
    },
    apolice(newVal, old) {
      if (newVal && newVal.PolicyUniqueId) {
        // this.newStartDate =  new Date(this.$moment().format());
        this.deadlineDays = this.apolice.DeadlineDays;
        this.endDate = this.apolice.EndDate;
        this.loadRestoreInsuredPremium();
        this.updateEndosso({
          ComplementaryDeadlineDays: this.apolice.ComplementaryDeadlineDays,
          ComplementaryEndDate: this.apolice.ComplementaryEndDate,
          ComplementaryInsuredAmountValue: this.apolice
            .ComplementaryInsuredAmountValue,
          DeadlineDays: this.apolice.DeadlineDays,
          EndDate: this.apolice.EndDate,
          StartDate: this.apolice.StartDate,
          InsuredAmountValue: this.apolice.InsuredAmountValue
        });
        if (this.$store.state["endosso-module"].hasReason) {
          this.tipoEndossoDescription = "Cancelamento";
          if (this.$store.state["endosso-module"].hasReason === 12) {
            this.tipoEndossoDescription = "Cancelamento por Erro";
          }
          if (this.$store.state["endosso-module"].hasReason === 13) {
            this.tipoEndossoDescription = "Cancelamento durante a vigência";
          }
          if (this.$store.state["endosso-module"].hasReason === 14) {
            this.tipoEndossoDescription =
              "Cancelamento após o  fim de vigência";
          }
          if (this.$store.state["endosso-module"].hasReason === 15) {
            this.tipoEndossoDescription = "Endosso de alteração comum";
          }
          if (this.$store.state["endosso-module"].hasReason === 16) {
            this.tipoEndossoDescription = "Endosso de alteração neutro";
          }
          if (this.$store.state["endosso-module"].hasReason === 17) {
            this.tipoEndossoDescription =
              "Cancelamento automático após fim de vigência";
          }
        } else {
          this.tipoEndossoDescription = "Cancelamento";
        }
      }
    }
  },
  mounted() {
    var portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    var Essor = parseInt(environment.CompanyId.Essor);
    this.Essor = portalConfig.CompanyId == Essor ? true : false;
    this.updateEndosso({
      EndorsementTypeId: 1,
      PolicyUniqueId: this.$route.params.policyUniqueId
    });
    // console.log("aqui", this.Rules);
  },
  created() {
    this.$appInsights.trackPageView({
      name: "endosso-cancelamento",
      url: window.location.href
    });
  },
  methods: {
    ...mapActions("endosso-module", ["restoreInsurancePremium"]),
    ...mapMutations("endosso-module", ["updateEndosso", "updateDeadlineDays"]),

    saveDate() {
      this.updateEndosso({
        EndDate: this.newStartDate
      });
      this.updateDeadlineDays(this.deadlineDays);
      this.modalStartDate = false;
      this.loadRestoreInsuredPremium()
    },

    async loadRestoreInsuredPremium() {
      this.setLoading();

      return this.restoreInsurancePremium()
        .then(response => {
          this.IsBankInformationNeeded = response.IsBankInformationNeeded;
          this.restituir = response.InsurancePremium <= 0;
          this.updateEndosso({
            RequestInsurancePremiumReview: response.InsurancePremium,
            RestoreInsurancePremium: response.RestoreInsurancePremium
          });

          this.loadingPremium = false;
          this.$forceUpdate();
        })
        .catch(errors => {
          this.loadingPremium = false;

          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },

    setLoading() {
      this.loadingPremium = true;
    }
  }
};
</script>

<style lang="scss">
.content-cancelamento {
  aside {
    @media (min-width: 768px) {
      border-left: 2px solid #e1e1e1;
      padding-left: 41px !important;
      padding-right: 0 !important;
    }

    @media (max-width: 767px) {
      padding-left: 1rem !important;
      padding-top: 2.5rem !important;
      margin-top: 2.5rem !important;
      margin-right: 1.5rem !important;
      border-top: 2px solid #e1e1e1;
    }

    .vx-row {
      margin-left: 0;

      &:not(:first-child) {
        border-top: 2px solid #e1e1e1;
      }
    }
  }

  section {
    .vx-row:not(:first-child) {
      border-top: 2px solid #e1e1e1;
    }
  }

  p {
    color: #000 !important;
  }

  .object-justify {
    text-align: justify;
  }
  .word-wrap {
    word-wrap: break-word;
  }
}

.field-edit .edit {
  font-weight: bold;
  font-size: 12px;
  margin: 0px 0px 0px -8px;
  border-radius: 20px;
  padding: 2px 8px;
  border: 1px solid transparent;
}
.field-edit .edit:hover {
  cursor: pointer;
  border: 1px solid rgba(var(--vs-primary), 1) !important;
  background: rgba(var(--vs-primary), 0.2);
}

.border-none {
  border-top: none !important;
}
.endosso-date input{
  height: 50px!important;
}
</style>
